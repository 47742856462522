/* Begin | Linhas */
.LinhasClass .container-linhas h2 {
    margin-bottom: 30px;
}
.LinhasClass .container-linhas {
    margin: 40px auto;
}
.LinhasClass .container-linhas .item-linhas {
    padding: 0;
    text-align: center;
    margin: 0 12px;
}
.LinhasClass .container-linhas .item-linhas a {
    background-color: #FFDD68;
    border-radius: 20px;
    display: block;
    padding: 20px;
    transition: 0.5s;
}
.LinhasClass .container-linhas .item-linhas a:hover {
    background-color: #FFC500;
}
.LinhasClass .container-linhas .item-linhas a:hover {
    text-decoration: none;
}
.LinhasClass .container-linhas .item-linhas a img {
    width: 100%;
    max-width: 170px;
    margin-bottom: 15px;
}
.LinhasClass .container-linhas a span {
    display: block;
    font-size: 20px;
    margin-bottom: 20px;
}
/* End | Linhas */

/* BEGIN RESPONSIVE */
/* Begin | 1200px */
@media (max-width: 1200px)
{
    .LinhasClass .container-linhas .item-linhas {
        margin: 0 10px;
    }
    .LinhasClass .container-linhas .item-linhas a img {
        max-width: 120px;
        margin-bottom: 10px;
    }
}
/* End | 1200px */
/* Begin | 992px */
@media (max-width: 992px)
{
    .LinhasClass .container-linhas .item-linhas {
        margin: 0 8px;
    }
    .LinhasClass .container-linhas .item-linhas a img {
        max-width: 80px;
        margin-bottom: 10px;
    }
    .LinhasClass .container-linhas a span {
        font-size: 16px;
    }
}
/* End | 992px */
/* End | 768px */
@media (max-width: 768px)
{
    .LinhasClass .container-linhas .RowItemLinhas {
        display: block;
    }
    .LinhasClass .container-linhas .RowItemLinhas .item-linhas {
        display: inline-block;
        margin: 0;
        width: 50%;
    }
    .LinhasClass .container-linhas .item-linhas a {
        padding-bottom: 20px;
        margin: 7px;
    } 
    .LinhasClass .container-linhas a span {
        font-size: 20px;
    }
}
/* End | 768px */
/* END RESPONSIVE */
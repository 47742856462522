.custom-toast-container {
    z-index: 1050; /* Garante que o Toast fique acima de outros elementos */
    top: 270px !important; 
}

.custom-toast-header {
    background-color: #FFD032 !important; /* Amarelo suave */
    color: black !important; /* Cor de texto preta para contraste */
    font-weight: bold;
    padding: 10px 15px; /* Ajuste o padding para uma aparência mais equilibrada */
    border-radius: 0.25rem 0.25rem 0 0; /* Bordas arredondadas no topo */
}

.custom-toast-body {
    background-color: #FFF3CD; /* Amarelo claro para o corpo */
    color: black; /* Texto preto para melhor legibilidade */
    font-weight: normal; /* Mude para normal para não sobrecarregar visualmente */
    padding: 15px 20px; /* Ajuste o padding para mais espaço */
    border-radius: 0 0 0.25rem 0.25rem; /* Bordas arredondadas no rodapé */
}

.alert-icon {
    margin-right: 10px;
    color: black; /* Altere para preto para melhor contraste com o fundo amarelo */
    font-size: 1.5rem;
}

.toast-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.toast-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.toast-exit {
    opacity: 1;
    transform: translateY(0);
}

.toast-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
}

/* Adicionando responsividade */
@media (max-width: 576px) {
    .custom-toast-container {
        top: 230px !important; /* Ajuste para telas menores */
    }
    
    .custom-toast-header {
        padding: 8px 10px; /* Ajusta o padding em telas menores */
        font-size: 14px; /* Ajuste conforme necessário */
    }

    .custom-toast-body {
        padding: 12px 15px; /* Ajusta o padding em telas menores */
        font-size: 12px; /* Ajuste conforme necessário */
    }
}
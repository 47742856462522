.NoticiasClass {
    margin-bottom: 40px;
}

.NoticiasClass .CapaNoticia {
    display: inline-block;
    margin-bottom: 20px;
}

.NoticiasClass .CapaNoticia img {
    width: 100%;
}

.NoticiasClass .CapaDetalheNoticia img {
    width: 100%;
    padding-right: 10px;
}

.NoticiasClass .CapaNoticia span {
    display: flex;
    align-content: flex-start;
    margin-bottom: 10px;
}

.NoticiasClass .CapaListNoticia img {
    width: 100%;
    padding-right: 10px;
}

.NoticiasClass .CapaListNoticia span {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.NoticiasClass .item-noticia {
    display: inline-block;
    color: #3a3a3a;
    margin-bottom: 0px;
}

.NoticiasClass .item-noticia h3 {
    font-size: 22px;
    margin-bottom: 0px;
}

.NoticiasClass .item-noticia h4 {
    font-size: 18px;
}

.NoticiasClass .item-noticia .linha-divisoria {
    width: 100%;
    margin: 20px 0;
}

.NoticiasClass .item-noticia:last-child .linha-divisoria {
    display: none;
}
.NoticiasClass .coluna-esquerda {
    min-height: 700px;
}
.NoticiasClass .coluna-esquerda .wrap-noticias {
    display: flex;
}
.NoticiasClass .coluna-esquerda .CapaNoticia {
    width: 40%;
    margin-right: 20px;
}
.NoticiasClass .coluna-esquerda .CapaNoticia span {
    display: block;
    margin-bottom: 0;
}
.NoticiasClass .coluna-esquerda .item-noticia {
    width: 60%;
}
.NoticiasClass .coluna-esquerda .item-noticia span {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.NoticiasClass .coluna-esquerda .item-noticia h4 {
    font-size: 14px;
}

.NoticiasClass .coluna-esquerda .item-detailnoticia {
    width: 100%;
}

.NoticiasClass .coluna-direita {
    min-height: 700px;
    padding: 0;
    overflow: hidden;
}

/* End | Box Search */
.NoticiasClass .coluna-direita .BoxSearch {
    position: relative;
    margin: 20px 10px;
}
.NoticiasClass .coluna-direita .BoxSearch .input-busca {
    background-color: #ededed;
    border-color: #ededed;
    border-radius: 12px;
    padding: 10px;
    padding-left: 14px;
}
.NoticiasClass .coluna-direita .BoxSearch .input-busca::placeholder {
    color: #909090;
    font-weight: bold;
    font-style: italic;
}
.NoticiasClass .coluna-direita .BoxSearch .IcoSearch {
    color: #b6b6b6;
    display: block;
    position: absolute;
    font-size: 18px;
    top: 4px;
    right: 1px;
    cursor: pointer;
}
.NoticiasClass .coluna-direita .BoxSearch .IcoSearch:hover {
    color: black;
}
/* End | Box Search */

.NoticiasClass .coluna-direita .item-noticia:first-child {
    margin-top: 0;
}

/* Begin | Box Scroll */
.NoticiasClass .coluna-direita .box-scroll {
    padding-right: 20px;
    margin: 20px;
    /* height: 1080px; */
    height: 1260px;
    overflow-y: scroll;
}
.NoticiasClass .coluna-direita .box-scroll::-webkit-scrollbar {
    background-color: #e0e0e0;
    border-radius: 50px;
    width: 8px;
    height: 3px;
    /* A altura só é vista quando a rolagem é horizontal */
}

.NoticiasClass .coluna-direita .box-scroll::-webkit-scrollbar-track {
    background: transparent;
    padding: 2px;
}
.NoticiasClass .coluna-direita .box-scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #474747;
}
/* End | Box Scroll */

.NoticiasClass .coluna-direita h3 {
    font-size: 14px;
    margin-top: 10px;
}

.NoticiasClass .coluna-direita h4 {
    font-size: 14px;
}

.NoticiasClass .coluna-direita p {
    font-size: 14px;
    line-height: 20px;
    display: block;
}

.NoticiasClass .coluna-direita .linha-divisoria {
    margin: 10px auto;
    width: 100%;
}

/* BEGIN RESPONSIVE */
/* End | 768px */
@media (max-width: 768px) {
    .NoticiasClass .coluna-esquerda .wrap-noticias {
        display: block;
    }
    .NoticiasClass .coluna-esquerda .item-noticia {
        display: block;
        width: 100%;
    }
    .NoticiasClass .coluna-esquerda .CapaNoticia {
        display: block;
        width: 100%;
        margin-right: 0;
    }
}
/* End | 768px */
/* END RESPONSIVE */

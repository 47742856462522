.CatalogoClass {
    margin-bottom: 40px;
}

.CatalogoClass .box-gray img {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 10px;
}

.CatalogoClass .box-gray h3 {
    font-size: 12px;
    font-weight: bold;
}

.CatalogoClass .box-gray p {
    line-height: 21px;
}
.CatalogoClass .box-gray p:last-child {
    margin-bottom: 0;
}
.CatalogoClass .box-gray {
    min-height: 490px;
}
.CatalogoClass .NoSpace {
    float: left;
}

.CatalogoClass .TextoAplicacaoPruduto {
    font-size: 12px;
}
.CatalogoClass .TextoAplicacaoPrudutoBold {
    font-weight: bold;
}

.BTCard {
    width: 25%;
}

.modal-body img {
    width: 100%;
}
.modal-footer {
    display: block;
}

.modal-backdrop.show {
    opacity: 0.8 !important;
}

.modal.show .modal-dialog {
    width: 520px;
    margin: 6% auto;
}

.modal-content {
    margin-top: 6% auto;
    width: 520px;
}
.pagination {
    border: #000;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination .active > .page-link,
.page-link.active {
    background-color: #ffc500;
    color: #000;
    border: #000;
    font-weight: bold;
}

.pagination :hover {
    color: ffc500;
}
/* .pagination span {
    color: #ffc500;
} */
.pagination a {
    color: #000;
}
.pagination a:hover {
    color: #000;
    background-color: #ffc500;
}

/* BEGIN RESPONSIVE */
/* Begin | 1200px */
@media (max-width: 1200px) {
    .BTCard {
        width: 50%;
    }

    .modal.show .modal-dialog {
        width: 520px;
        margin: 6% auto;
    }

    .modal-content {
        margin: 6% auto;
        width: 520px;
        align-items: center;
    }
}
/* End | 1200px */
/* End | 991px */

/* Begin | 768px */
@media (max-width: 768px) {
    .BTCard {
        width: 100%;
    }

    .modal.show .modal-dialog {
        width: 360px;
        margin: 6% auto;
    }

    .modal-content {
        margin: 6% auto;
        width: 360px;
        align-items: center;
    }
}
/* End | 768px */

/* Begin | 600px */
@media only screen and (max-width: 576px) {
    .modal.show .modal-dialog {
        width: 380px;
        margin: 6% auto;
    }

    .modal-content {
        margin: 6% auto;
        width: 90%;
        align-items: center;
    }
}
/* End | 768px */
/* END RESPONSIVE */

.BannerClass .banner-principal {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 40px;
}

.BannerClass,
img {
    width: 100%;
}

/* End | Notícias */
.HomeClass .container-noticias .BoxNoticia {
    display: flex;
}
.HomeClass .BoxNoticia .col-right {
    margin: 0 40px;
}

.HomeClass .container-noticias .BoxNoticia .ThumbNoticias {
    border-radius: 18px;
}

.HomeClass .container-noticias .BoxNoticia h3 {
    margin: 8px 0;
}

.HomeClass .container-noticias .BoxNoticia p {
    margin-bottom: 18px;
}

.HomeClass .container-noticias .BoxNoticia .bt-noticias {
    border: 1px solid #3a3a3a;
    border-radius: 50px;
    color: #3a3a3a;
    text-decoration: none;
    padding: 3px 14px;
    transition: 0.5s;
}
.HomeClass .container-noticias .BoxNoticia .bt-noticias:hover {
    background-color: #3a3a3a;
    color: white;
}
/* End | Notícias */

/* BEGIN RESPONSIVE */
/* End | 768px */
@media (max-width: 768px) {
    .HomeClass .BoxNoticia {
        display: block !important;
    }
    .HomeClass .col-left {
        width: 100%;
        margin-bottom: 20px;
    }
    .HomeClass .col-right {
        width: 100%;
    }
    .HomeClass .container-noticias .BoxNoticia .ThumbNoticias {
        width: 100%;
        height: 170px;
        object-fit: cover;
    }
    .HomeClass .BoxNoticia .col-right {
        margin: 0 0;
    }
    .HomeClass .container-noticias .BoxNoticia p {
        margin-bottom: 18px;
    }
    .HomeClass .linha-divisoria {
        width: 100%;
    }
}
/* End | 768px */
/* END RESPONSIVE */

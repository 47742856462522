.RepresentantesClass .box-title,
.RepresentantesClass .combo-UF {
    display: inline-block;
}

.RepresentantesClass .combo-UF {
    margin-left: 18px;
    vertical-align: super;
}

.RepresentantesClass h2 {
    margin-bottom: 20px;
}

.RepresentantesClass .accordion-button:not(.collapsed) {
    color: black;
    background-color: rgba(255, 208, 70, 0.1);
    box-shadow: inset 0 yellow;
    font-weight: bold;
}

.RepresentantesClass .accordion-item {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(255, 197, 0, 0.25);
}

.RepresentantesClass table tr {
    border-bottom: rgba(237, 237, 237, 0.75);
}

.RepresentantesClass table tbody td:first-child {
    text-align: center;
}

.RepresentantesClass table td {
    padding: 20px 0;
}

.RepresentantesClass table td span {
    background-color: #ffd032;
    border-radius: 6px;
    padding: 6px 12px;
}

.RepresentantesClass table td span svg {
    color: #5b1600;
}

.RepresentantesClass .RowCards .card {
    margin-bottom: 40px;
}

.RepresentantesClass .card-title {
    margin-bottom: 20px;
}

.RepresentantesClass .card-text {
    display: flex;
}

.RepresentantesClass .card-text .imgLogoRepresentantes {
    width: 30%;
}

.RepresentantesClass .card-text .imgLogoRepresentantes img {
    width: 100%;
}

.RepresentantesClass .card-text .conteudoRepresentantes .txtRepresentantes {
    line-height: 20px;
    width: 80%;
    padding: 0 10px;
    margin-bottom: 8px;
}

.RepresentantesClass .box-representantes {
    margin-bottom: 20px;
}

.RepresentantesClass .combo-UF .form-select {
    cursor: pointer;
}

/* BEGIN RESPONSIVE */
/* End | 991px */
@media (max-width: 991px) {
    .RepresentantesClass .card-title {
        margin-bottom: 20px;
    }
    .imgLogoRepresentantes {
        margin-right: 20px;
    }
}
/* End | 991px */
/* Begin | 576px */
@media (max-width: 576px) {
    .RepresentantesClass .card-text {
        display: block;
        text-align: center;
    }
    .RepresentantesClass .card-title {
        text-align: center;
        margin-bottom: 0;
        font-size: 20px;
    }
    .imgLogoRepresentantes {
        display: block;
        text-align: center;
        width: 100% !important;
    }
    .imgLogoRepresentantes img {
        width: 26% !important;
    }
    .RepresentantesClass .card-text .conteudoRepresentantes .txtRepresentantes {
        width: 100%;
    }
}
/* End | 576px */
/* END RESPONSIVE */

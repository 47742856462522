.language-switcher {
    display: flex;
    align-items: center;
}

.language-switcher > button {
    cursor: pointer;
    background:none;
    border: none;
    height: 70px;
}


/* Begin | TopHeaderClass */
.TopHeaderClass {
    background-color: black;
    color: white;
    font-size: 12px;
    padding: 8px 0;
    top: 0;
}
.TopHeaderClass .coluna-esquerda {
    margin: 0;
}
.TopHeaderClass .coluna-direita {
    text-align: right;
    margin: 0;
}
.TopHeaderClass .coluna-direita .lista-sm-direita {
    display: none;
}
.TopHeaderClass ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.TopHeaderClass ul li {
    display: inline-block;
    padding: 0 6px;
}

.TopHeaderClass ul li a {
    color: white;
    text-decoration: none;
    transition: 0.3s;
}

.TopHeaderClass ul li a svg {
    font-size: 15px;
}

.TopHeaderClass ul li a:hover {
    color: #ffd032;
}

.TopHeaderClass .ListContact li {
    padding: 0 8px;
}

.TopHeaderClass .language-switcher {
    margin: 0;
    padding: 0;
}

.TopHeaderClass .language-switcher button {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0 4px;
}

.TopHeaderClass .language-switcher button img {
    width: 100%;
    max-width: 90%;
    opacity: 0.5;
    transition: 0.3s;
}

.TopHeaderClass .language-switcher button img:hover {
    opacity: 1;
}

/* End | TopHeaderClass */
/* Begin | HeaderClass */
.HeaderClass {
    background-color: #ffc500;
    padding: 26px 0;
    top: 36px;
}

.HeaderClass .BoxSearch {
    position: relative;
    margin-bottom: 0 !important;
    top: 8px;
}

.HeaderClass .BoxSearch .IcoSearch {
    display: block;
    position: absolute;
    font-size: 22px;
    top: 0;
    right: 8px;
    cursor: pointer;
}

.HeaderClass .BoxSearch input {
    border-radius: 40px;
    border: 0 none;
    padding: 10px 20px;
}

.HeaderClass .BoxSearch input::placeholder {
    color: #b6b6b6;
    font-weight: bold;
}

.HeaderClass .BoxSearch .IcoSearch {
    color: #b6b6b6;
}

.HeaderClass .BoxSearch .IcoSearch:hover,
.HeaderClass .BoxSearch .IcoSearch:active {
    color: black;
    background-color: transparent;
    border-color: transparent;
}

.HeaderClass .ListServices {
    background-color: #ffdd68;
    border-radius: 50px;
    display: block;
    list-style: none;
    position: relative;
    top: 8px;
}

.HeaderClass .ListServices li {
    display: inline-block;
}

.HeaderClass .ListServices li a {
    color: #3a3a3a;
    display: block;
    padding: 11px;
}
.HeaderClass .navbar-brand {
    display: block;
}
.HeaderClass .navbar-brand img {
    width: 100%;
    max-width: 268px;
}
/* End | HeaderClass */
/* Begin | BoxNavbar */
.BoxNavbar {
    background-color: #ffd032;
    -webkit-box-shadow: 0px 16px 17px -13px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 16px 17px -13px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 16px 17px -13px rgba(0, 0, 0, 0.25);
    margin-bottom: 40px;
    top: 150px;
}
.BoxNavbar .NavCatalogo .BoxListServices {
    display: none;
}
.BoxNavbar .nav-item {
    margin: 0 6px;
}
.BoxNavbar .nav-item a {
    text-decoration: none;
}
.BoxNavbar .donwload-pdf {
    display: none !important;
    text-align: center;
}
.BoxNavbar .donwload-pdf img {
    width: 100%;
    max-width: 20px;
}
.BoxNavbar .donwload-pdf a {
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 16px;
}
.BoxNavbar .nav-item:first-child {
    margin-left: 0;
    padding-left: 0;
}
.BoxNavbar .nav-item:first-child .nav-link {
    padding-left: 0;
}
/* Begin | Animação do Link */
.BoxNavbar .nav-link {
    display: flex;
    position: relative;
    color: #3a3a3a;
}
.BoxNavbar .nav-link::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f39400;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.BoxNavbar .nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
/* End | Animação do Link */
.BoxNavbar .nav-link:hover {
    color: black;
}

.BoxNavbar .active {
    color: black;
    font-weight: bold;
}

.BoxNavbar .nav-link span {
    display: inline-block;
}

.BoxNavbar .nav-link .IcoNavMenu {
    width: 100%;
    max-width: 20px;
    margin-right: 2px;
}

.NavCatalogo {
    text-align: right;
    padding: 12px 0;
}

.NavCatalogo p {
    display: inline-flex;
    font-size: 14px;
    font-weight: bold;
    margin: 0 67px 0 0;
    padding: 0;
    text-align: right;
}

.NavCatalogo p span {
    margin-right: 4px;
}

.NavCatalogo p img {
    width: 100%;
    max-width: 18px;
}

.NavCatalogo ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0 0 8px 0;
}

.NavCatalogo ul li {
    display: inline-block;
    margin-left: 6px;
}

.NavCatalogo ul li a {
    border-radius: 50px;
    border: 1px solid #3a3a3a;
    color: #3a3a3a;
    font-size: 14px;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    /* width: 120px; versão para dois botoes Desktop e Pdf */
    width: 240px;
    text-align: center;
    transition: 0.5s;
}

.NavCatalogo ul li a:hover {
    background-color: #3a3a3a;
    color: white;
}

/* End | BoxNavbar */

/* BEGIN RESPONSIVE */
/* Begin | 1400px */
@media (max-width: 1400px) {
    .HeaderClass .ListServices {
        padding: 3px 10px;
        margin: 0;
        display: table;
        float: right;
    }
    .HeaderClass .ListServices li a {
        padding: 8px;
    }
    .BoxNavbar {
        top: 140px;
    }
    .MarginTopBody {
        margin-top: 286px;
    }
}
/* End | 1400px */
/* Begin | 1200px */
@media (max-width: 1200px) {
    .HeaderClass .ListServices {
        padding: 3px 10px;
        margin: 0;
        display: table;
        float: right;
    }
    .BoxNavbar {
        top: 134px;
        padding: 10px 0;
    }
    .MarginTopBody {
        margin-top: 315px;
    }
    .HeaderClass .BoxSearch {
        margin-left: 40px;
        top: 0;
    }
    .HeaderClass .ListServices {
        margin-left: 40px;
        top: 0;
    }
    .HeaderClass .BoxSearch input::placeholder {
        font-size: 13px;
    }
    .NavCatalogo p {
        margin: 0 31px 0 0;
    }
    .BoxNavbar .nav-item {
        margin: 0 10px;
        display: block;
    }
    .NavCatalogo ul li a {
        padding: 6px 0;
        width: 90px;
    }
}
/* End | 1200px */

/* Begin | 992px */
@media (max-width: 992px) {
    .HeaderClass {
        top: 36px;
    }
    .TopHeaderClass .coluna-esquerda {
        display: none;
    }
    .TopHeaderClass ul {
        display: inline-block !important;
        margin: 0 auto;
    }
    .TopHeaderClass ul:first-child {
        margin-right: 0;
    }
    .HeaderClass .col-pesquisa {
        display: none;
    }
    .BoxNavbar .NavDefault {
        display: inline-block;
        width: 50%;
    }
    .BoxNavbar .nav-item:first-child {
        margin: 0 10px;
    }
    .BoxNavbar .NavCatalogo {
        display: inline-block;
        width: 50%;
    }
    .BoxNavbar .NavCatalogo > div,
    .BoxNavbar .NavCatalogo > ul {
        margin-right: 18px;
    }
    .BoxNavbar .nav-link .IcoNavMenu {
        margin-right: 8px;
    }
    .BoxNavbar .donwload-pdf img {
        display: inline-block;
        margin-right: 8px;
    }
}
/* End | 992px */

/* End | 768px */
@media (max-width: 768px) {
    .HeaderClass {
        top: 34px;
    }
    .TopHeaderClass .coluna-esquerda {
        display: none;
    }
    .TopHeaderClass ul {
        display: inline-block !important;
        margin: 0 auto;
    }
    .TopHeaderClass .coluna-direita {
        text-align: center;
        margin: 0 auto;
    }
    .TopHeaderClass .coluna-direita .lista-sm-direita {
        display: none !important;
    }
    .TopHeaderClass ul:first-child {
        margin-right: 20px;
    }
    .HeaderClass .col-pesquisa {
        display: none;
    }
    .HeaderClass .col-logo {
        width: 100%;
        text-align: center;
    }
    .HeaderClass .col-servicos {
        display: none;
    }
    .HeaderClass .ListServices {
        margin-left: 0;
    }
    .HeaderClass .ListServices {
        background-color: transparent;
        padding: 7px 0 0 0;
    }
    .BoxNavbar .NavCatalogo .box-downloads,
    .BoxNavbar .NavCatalogo .list-downloads {
        display: none;
    }
    .BoxNavbar .NavCatalogo .BoxListServices {
        display: block;
        padding: 0;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul {
        padding: 0 8px;
        margin: 0;
        background-color: #3a3a3a;
        border-radius: 50px;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul li {
        color: white;
        padding: 0;
        margin: 0;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul li a {
        border: 0 none;
        color: white;
        padding: 6px 8px;
        margin: 0;
        width: auto;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul li a:hover {
        color: #ffd032;
    }
    .BoxNavbar .NavCatalogo .bandeiras .language-switcher {
        display: block;
    }
    .BoxNavbar .NavCatalogo .bandeiras .language-switcher button {
        height: auto;
        padding: 0;
    }
    .BoxNavbar .NavCatalogo .bandeiras .language-switcher img {
        width: 100%;
        padding: 6px 7px;
    }
    .BoxNavbar .nav-link .IcoNavMenu {
        margin-right: 8px;
    }
    .BoxNavbar .donwload-pdf {
        display: block !important;
    }
    .BoxNavbar .donwload-pdf img {
        display: inline-block;
        margin-right: 8px;
    }
    .BoxNavbar .donwload-pdf a {
        display: inline-block;
        position: relative;
        top: 4px;
    }
    .TopHeaderClass .ListContact .phone,
    .TopHeaderClass .ListContact .email {
        display: none !important;
    }
}
/* End | 768px */

/* End | 576px */
@media (max-width: 576px) {
    .TopHeaderClass .coluna-direita {
        text-align: center;
    }
    .TopHeaderClass .coluna-direita .lista-sm-direita {
        display: none !important;
    }
    .HeaderClass .ListServices {
        padding: 0;
    }
    .BoxNavbar {
        top: 134px;
    }
    .BoxNavbar .nav-item:first-child,
    .BoxNavbar .nav-item {
        margin: 0;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul li a {
        padding: 6px 6px;
    }
}
/* End | 576px */

/* End | 456px */
@media (max-width: 456px) {
    .BoxNavbar .NavCatalogo .BoxListServices ul {
        background-color: transparent;
        padding: 0;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul li {
        color: #3a3a3a;
    }
    .BoxNavbar .NavCatalogo .BoxListServices ul li a {
        color: #3a3a3a;
        font-weight: bold;
        padding: 6px;
        font-size: 12px;
    }
}
/* End | 456px */

/* END RESPONSIVE */

body {
    color: #3a3a3a;
}
a {
    color: #3a3a3a;
    text-decoration: none;
}
a:hover {
    color: #3a3a3a;
    text-decoration: underline;
}
/* H2 */
h2 {
    color: #3a3a3a;
    font-size: 22px;
    display: flex;
}
h2 img {
    width: 100%;
    max-width: 30px;
    margin-right: 8px;
}
h2 span {
    display: block;
}

/* Contorno Linha */
.ContornoLinha {
    border: 1px solid #3a3a3a;
    border-radius: 30px;
    margin: 10px 0;
    padding: 30px;
}

/* FORMS */
.form-control:focus,
.form-select:focus {
    border-color: #FF6B00;
    box-shadow: 0 0 0 0.25rem rgba(255,107,0,.25);
}

/* BOX YELLOW DATE (data da notícia) */
.box-yellow-date {
    background-color: #FFD032;
    border-radius: 50px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 18px;
}
/* Box gray (catálogo) */
.box-gray {
    background-color: #F5F5F5;
    border-radius: 12px;
    padding: 20px;
    margin: 0 6px 12px;
}

/* COLUNAS ESQUERDA / DIREITA */
.coluna-esquerda {
    margin-right: 10px;
}
.coluna-direita {
    margin: 10px 0px 10px 0px;
}

/* SETA SLIDER */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    box-shadow: 0px 0px 26px 26px rgba(0,0,0,0.75);
    background-color: black;
    border-radius: 50px;
    background-size: 18px;
    -webkit-box-shadow: 0px 0px 26px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 26px -4px rgba(0,0,0,0.75);
}

/* PROPRIEDADES DE APOIO */
.dn {
    display: none !important;
}
.df {
    display: flex !important;
}
.NoSpace {
    margin: auto !important;
    padding: 0 !important;
}
.noFlex {
    display: inherit !important;
}
.ta-left {
    text-align: left !important;
}
.ta-center {
    text-align: center !important;
}
.ta-right {
    text-align: right !important;
}
.PosFixed {
    position: fixed;
    width: 100%;
    z-index: 1000;
}
.MarginTopBody {
    margin-top: 290px;
}
.SpaceTop40 {
    margin-top: 40px;
} 
.SpaceBottom40 {
    margin-bottom: 40px;
} 

.Negrito {
    font-weight: bold !important;
}
.linha-divisoria {
    background-color:rgb(58, 58, 58, 0.25);
    margin: 40px auto;
    height: 1px;
    width: 75%;
}

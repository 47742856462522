/* Begin Footer */
.FooterClass {
    background-color: #3a3a3a;
    color: white;
    font-size: 15px;
    padding: 40px 0 20px;
}

.FooterClass .coluna-logo ul {
    padding: 0;
    margin: 0;
}

.FooterClass .coluna-logo li {
    list-style: none;
}

.FooterClass .coluna-logo ul li img {
    width: auto;
}
.FooterClass .list-navegacao .MenuFooter img {
    display: none;
}

.FooterClass .list-navegacao,
.FooterClass .list-servicos {
    display: inline-block;
}

.FooterClass .MenuFooter {
    display: inline-table;
    list-style: none;
    margin: 20px 0 0;
    padding-left: 0;
}

.FooterClass .list-servicos {
    margin-left: 135px;
}

.FooterClass .MenuFooter li {
    color: #ffdd68;
    margin: 4px;
}

.FooterClass .MenuFooter li a {
    color: white;
    display: block;
    padding: 4px 10px;
    transition: 0.2s;
}

.FooterClass h3 {
    font-size: 16px;
    font-weight: bold;
}

.FooterClass address,
.FooterClass p {
    margin-bottom: 0.5em;
}

.FooterClass p span a {
    color: white;
    text-decoration: underline;
    transition: 0.2s;
}

.FooterClass p span a:hover {
    color: #ffdd68;
}

.FooterClass p svg {
    margin-right: 6px;
}

.FooterClass .endereco-comercial {
    display: inline-block;
}

.FooterClass .endereco-fabrica {
    display: inline-block;
}
.FooterClass .col-socialmedia {
    text-align: center;
}
.FooterClass .list-socialmedia {
    list-style: none;
    display: block;
    margin: 0 auto;
    padding: 0;
}
.FooterClass .list-socialmedia li {
    display: inline-block;
}
.FooterClass .list-socialmedia li a {
    color: white;
    display: block;
    font-size: 18px;
    padding: 8px;
    margin: 0 4px;
}
/* End FooterBottomClass */
.FooterBottomClass {
    background-color: black;
    color: #ffd032;
    font-size: 12px;
    text-align: center;
    padding: 16px 0;
}

/* End FooterBottomClass */

/* BEGIN RESPONSIVE */

/* Begin | 1400px */
@media (max-width: 1400px) {
    .FooterClass .coluna-1 .list-servicos {
        margin-left: 80px;
    }

    .FooterClass .coluna-2 .endereco-comercial {
        display: block;
        margin-right: 0;
        margin-bottom: 40px;
    }

    .FooterClass .coluna-2 .endereco-fabrica {
        display: block;
    }

    .FooterClass .coluna-2 h3 {
        color: #ffdd68;
    }

    .FooterClass .coluna-2 p {
        margin-bottom: 0;
    }
}

/* End | 1400px */

/* Begin | 991px */
@media (max-width: 991px) {
    .FooterClass .coluna-logo {
        text-align: center;
        margin-bottom: 24px;
    }

    .FooterClass .coluna-1,
    .FooterClass .coluna-2 {
        text-align: center;
    }

    .FooterClass .coluna-1 {
        margin-bottom: 40px;
    }

    .FooterClass .MenuFooter li.Negrito {
        text-align: left;
        padding: 4px 10px;
    }

    .FooterClass .MenuFooter li a {
        text-align: left;
    }

    .FooterClass .coluna-2 .endereco-comercial {
        display: inline-block;
        text-align: left;
        margin-left: 0;
        margin-right: 20px;
    }

    .FooterClass .coluna-2 .endereco-fabrica {
        display: inline-block;
        text-align: right;
        margin-right: 0;
        margin-left: 20px;
    }
}

/* End | 991px */

/* Begin | 767px */
@media (max-width: 767px) {
    .FooterClass .coluna-1,
    .FooterClass .coluna-2 {
        text-align: center;
    }

    .FooterClass .coluna-1 {
        margin-bottom: 40px;
    }

    .FooterClass .coluna-1 .list-servicos {
        margin-left: 20px;
    }

    .FooterClass .coluna-2 .endereco-comercial {
        display: block;
        text-align: center;
        margin-right: 0;
    }

    .FooterClass .coluna-2 .endereco-fabrica {
        display: block;
        text-align: center;
        margin-left: 0;
    }
    .FooterClass .col-socialmedia {
        margin-top: 40px;
    }
}

/* End | 767px */

/* END RESPONSIVE */

.BreadcrumbsClass {
    margin-bottom: 40px;
} 
.BreadcrumbsClass .IcoBreadcrumbs {
    display: inline-block;
} 
.BreadcrumbsClass ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 12px;
} 
.BreadcrumbsClass ul li {
    display: inline-block;
    font-size: 22px;
    padding: 0 4px;
}
.BreadcrumbsClass ul li .active {
    font-weight: bold;
}
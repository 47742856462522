.InstitucionalClass {
    margin-top: -20px;
}

.coluna-esquerda .nav-tabs {
    border: 0 none;
}

.coluna-esquerda .nav-tabs .nav-item button.nav-link {
    background-color: transparent;
    border: 1px solid #3a3a3a;
    border-radius: 50px;
    color: #3a3a3a;
    margin-right: 16px;
    transition: 0.5s;
}

.coluna-esquerda .nav-tabs .nav-item button.active {
    background-color: #3a3a3a;
    color: white;
}

.InstitucionalClass .ContornoLinha img {
    border-radius: 12px;
    width: 100%;
}

.InstitucionalClass .coluna-direita {
    margin-top: 70px;
}

.InstitucionalClass .coluna-esquerda .ContornoLinha:last-child {
    margin-top: 30px;
}
.InstitucionalClass h3 {
    font-size: 22px;
    line-height: 26px;
}

.InstitucionalClass h3 span {
    display: block;
    font-size: 16px;
    font-weight: 400;
}

.InstitucionalClass h4 {
    font-size: 20px;
}
.InstitucionalClass .coluna-esquerda .ContornoLinha .code-conduct h4 a {
    text-decoration: none;
}
.InstitucionalClass h4::before {
    content: "";
    display: inline-block;
    height: 18px;
    width: 12px;
    background-color: #ffd032;
    margin-right: 6px;
    position: relative;
    top: 2px;
}

.InstitucionalClass p {
    margin-bottom: 28px;
}

.InstitucionalClass p a {
    font-weight: bold;
    text-decoration: underline;
}
.InstitucionalClass p a:hover {
    color: #f39400;
}

.InstitucionalClass .linha-divisoria {
    width: 100%;
}
.InstitucionalClass .coluna-direita h3,
.InstitucionalClass .coluna-direita p {
    text-align: center;
}

.InstitucionalClass .video-institucional {
    width: 100%;
    height: 315px;
}

/* .InstitucionalClass a {
    background-color: #ffdd68;
    border-radius: 20px;
    padding: 20px;
    transition: 0.5s;
} */

.divFlex {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.InstitucionalClass .whatsLink {
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    border-radius: 20px;
    padding: 10px;
    background-color: rgb(37, 211, 102);
    color: white;
}

/* BEGIN RESPONSIVE */
/* Begin | 1400px */
@media (max-width: 1400px) {
}
/* End | 1400px */

/* Begin | 1200px */
@media (max-width: 1200px) {
}
/* End | 1200px */

/* Begin | 991px */
@media (max-width: 991px) {
}
/* End | 991px */

/* Begin | 768px */
@media (max-width: 768px) {
    .coluna-esquerda .nav-tabs {
        display: table;
    }
    .coluna-esquerda .nav-tabs .nav-item button.nav-link {
        margin-top: 20px;
        width: 100%;
    }
}
/* End | 768px */

/* Begin | 576px */
@media (max-width: 576px) {
}
/* End | 576px */

/* Begin | FiltroClass */
.FiltroClass .container {
    background-color: #ededed;
    border-radius: 18px;
    margin: 40px auto;
}
.FiltroClass .container .row {
    padding: 20px 0 0;
}
.FiltroClass .container .line-1 > div,
.FiltroClass .container .line-2 > div {
    padding: 0 10px 0 20px;
}
.FiltroClass .container .line-1 > div:last-child,
.FiltroClass .container .line-2 > div:last-child {
    padding: 0 20px 0 10px;
}
.FiltroClass .container .line-1 .mb-3,
.FiltroClass .container .line-2 .mb-3 {
    margin-bottom: 0 !important;
}
.FiltroClass .container .row:last-child {
    padding-bottom: 20px;
}
.FiltroClass .container .col {
    padding: 0 20px;
}
.FiltroClass .container .col > div {
    padding: 0;
    margin-bottom: 0 !important;
}
.FiltroClass .container .col input:hover::placeholder {
    color: #595959;
    transition: 0.5s;
}
.FiltroClass .container .col input:hover {
    background-color: #ffd032;
    transition: 0.5s;
}
.FiltroClass .BtFiltro {
    width: 100%;
    background-color: #3a3a3a;
}
.FiltroClass .BtFiltro:hover {
    background-color: #ffc500;
    color: #3a3a3a;
    border: 1px solid #ffc500;
    transition: 0.5s;
}

.FiltroClass .Loading {
    background-color: #ffc500;
    color: #3a3a3a;
    border: 1px solid #ffc500;
}

.FiltroClass .BoxLinha {
    text-align: center;
}
.FiltroClass .BoxLinha .ItenLinha {
    margin: 0 20px;
    display: inline-block;
    position: relative;
}
.FiltroClass .BoxLinha .ItenLinha a {
    color: #3a3a3a;
    display: block;
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
}
.FiltroClass .BoxLinha .ItenLinha a:hover {
    background-color: white;
}
.FiltroClass .BoxLinha .ItenLinha a img {
    width: 100%;
    margin-right: 8px;
}
.FiltroClass .BoxLinha .ItenLinha a .IcoLeve {
    max-width: 30px;
}
.FiltroClass .BoxLinha .ItenLinha a .IcoPesada {
    max-width: 25px;
}
.FiltroClass .BoxLinha .ItenLinha a .IcoAgricola {
    max-width: 25px;
}
.FiltroClass .BoxLinha .ItenLinha a .IcoVoltex {
    max-width: 35px;
}
.FiltroClass .BoxLinha .ItenLinha a .IcoNavLancamentos {
    max-width: 20px;
}
.FiltroClass .BoxLinha .ItenLinha a span {
    vertical-align: middle;
    display: inline-block;
}
/* Begin | Animação do Link */
.FiltroClass .BoxLinha .ItenLinha a::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f39400;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.FiltroClass .BoxLinha .ItenLinha a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
/* End | Animação do Link */
.FiltroClass .BoxLinha .ItemLancamento a {
    border: 1px solid #3a3a3a;
    border-radius: 50px;
}
.FiltroClass .BoxLinha .ItemLancamento a::after {
    background-color: transparent;
}
/* End | FiltroClass */

/* BEGIN RESPONSIVE */
/* Begin | 1400px */
@media (max-width: 1400px) {
    .FiltroClass .container .line-1 > div,
    .FiltroClass .container .line-2 > div {
        padding: 0 10px;
    }
    .FiltroClass .container .line-1 > div:first-child,
    .FiltroClass .container .line-2 > div:first-child {
        padding: 0 10px 0 20px;
    }
    .FiltroClass .BoxLinha {
        padding: 0 20px;
    }
    .FiltroClass .BoxLinha .ItenLinha {
        margin: 0 0px;
    }
}
/* End | 1400px */

/* Begin | 1200px */
@media (max-width: 1200px) {
    .FiltroClass {
        margin-top: 240px;
    }
}
/* End | 1200px */

/* Begin | 991px */
@media (max-width: 991px) {
    .FiltroClass .container .line-1 > div,
    .FiltroClass .container .line-2 > div {
        padding: 0 5px 0 10px !important;
    }
    .FiltroClass .BoxLinha .ItemLancamento {
        margin-top: 10px;
        width: 100% !important;
    }
}
/* End | 991px */

/* Begin | 768px */
@media (max-width: 768px) {
    .FiltroClass .container .line-1 > div,
    .FiltroClass .container .line-2 > div {
        padding: 10px 20px !important;
    }
    .FiltroClass .container .line-1 > div:last-child {
        padding: 10px 20px 0 !important;
    }
    .FiltroClass .container .line-2 > div:first-child {
        padding: 0 10px 10px 20px !important;
    }
    .FiltroClass .container .line-2 > div:first-child {
        padding: 0 20px 10px !important;
    }
    .FiltroClass .BoxLinha .ItenLinha {
        width: 50%;
    }
    .FiltroClass .BoxLinha .ItenLinha a {
        text-align: left;
        padding: 0 0 15px 48px;
    }
    .FiltroClass .BoxLinha .ItemLancamento a {
        padding: 8px;
        text-align: center;
        width: 100% !important;
    }
}
/* End | 768px */

/* Begin | 576px */
@media (max-width: 576px) {
    .FiltroClass {
        margin-top: 235px;
    }
    .FiltroClass .BoxLinha .ItenLinha a {
        padding: 0 0 15px 35px;
        font-size: 0.85em;
    }
    .FiltroClass .BoxLinha .ItemLancamento a {
        padding: 8px;
        text-align: center;
    }
}
/* End | 576px */

/* Begin | 576px */
@media (max-width: 576px) {
    .FiltroClass {
        margin-top: 245px;
    }
}
/* End | 576px */

/* END RESPONSIVE */

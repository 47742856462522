.ContatoClass h3 {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
}

.ContatoClass h3::before {
    content: "";
    display: inline-block;
    height: 18px;
    width: 12px;
    background-color: #ffd032;
    margin-right: 6px;
    position: relative;
    top: 2px;
}
.ContatoClass p {
    text-align: center;
}
.ContatoClassPrivacyPolicy p {
    text-align: justify;
}

.ContatoClass button {
    font-size: 20px;
    width: 100%;
    padding: 10px 0;
}

.ContatoClass .btn-close {
    font-size: 20px;
    width: 100%;
    padding: 10px 0;
}

.ContatoClass button svg {
    margin-right: 6px;
}

.ContatoClass .ContornoLinha img {
    border-radius: 12px;
    width: 100%;
}
.ContatoClass .coluna-esquerda .ContornoLinha:last-child {
    margin-top: 30px;
}


/* Alinha o texto do header à esquerda */
.custom-toast-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .custom-toast-header-text {
    flex-grow: 1;
    text-align: left;
    font-size: 16px;
  }
  
  /* Estilo para o botão de fechamento à direita */
  .custom-toast-header .btn-close {
    margin-left: auto;
  }
  
  /* Corpo do Toast personalizado para sucesso */
  .custom-toast-body-success {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #155724; /* Cor de texto de sucesso */
    background-color: #d4edda; /* Fundo de sucesso */
    padding: 10px;
    border-radius: 4px;
  }
  
  .alert-icon {
    color: #28a745; /* Verde sucesso */
  }